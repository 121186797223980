import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';


import {dasa} from '../../classess/dasaClasses';
import { image } from 'html2canvas/dist/types/css/types/image';

@Injectable({
  providedIn: 'root'
})
export class commonService {
private subject = new Subject<any>();
  static doAPICall: any;

  constructor(private http :HttpClient) { }


sendMessage(message:string)
{
  this.subject.next(message)
}
getMessage(): Observable<any>
{
  return this.subject.asObservable();
}
clearMessages()
{
  this.subject.next;
}




  doAPICall(request:any, url:string) :Observable <any>
  {
      return  this.http.post(url ,request)
      .pipe(catchError(this.formatErrors))
  }

  doAPICall_await(request:any, url:string)
  {
      return lastValueFrom(this.http.post(url ,request));

      // try {
      //   const v= lastValueFrom(this.http.post(url ,request));
      //   return v;

      // } catch (error) {
      //   console.log("ERROR", error);
      // }

  }



  // AddUsers(user:any) :Observable <any>
  // {
  //     return  this.http.post(environment.hosturl ,user)
  //     .pipe(catchError(this.formatErrors))
  // }

  private formatErrors(error :any)
  {
    return throwError(error.error)
  }
  sendWHAPI(url: string,data:any): Observable<any> {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${environment.WHAPI_BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.WHAPI_URL+url, data, { headers: headers }).pipe(catchError(this.formatErrors));

  }
  getWhatsAppChats(count:number,offset:number): Observable<any> {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${environment.WHAPI_BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.WHAPI_URL}chats?count=${count}&offset=${offset}`, { headers: headers }).pipe(catchError(this.formatErrors));

  }

  // Get messages for a specific chat
  getWhatsAppMessages(chatId: string,count:number,offset:number): Observable<any> {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${environment.WHAPI_BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    });
    return this.http.get(`${environment.WHAPI_URL}messages/list/${chatId}?count=${count}&offset=${offset}`, { headers: headers }).pipe(catchError(this.formatErrors));
  }

  // Send a message to a specific chat
  sendWhatsAppMessage(chatId: string, message: string): Observable<any> {
    return this.http.post(`${environment.WHAPI_URL}chats/${chatId}/messages`, { message });
  }
  fetchWhatsAppImage(mediaId: string): Promise<string | ArrayBuffer | null> {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${environment.WHAPI_BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    });
  
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.WHAPI_URL}media/${mediaId}`, {
        headers,
        responseType: 'blob' // Fetch the response as a Blob
      }).subscribe(
        blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); // Convert blob to a DataURL
          reader.onloadend = () => {
            resolve(reader.result); // Resolve the promise with the result
          };
          reader.onerror = (error) => {
            reject(error); // Reject the promise if an error occurs
          };
        },
        error => {
          reject(error); // Handle HTTP request errors
        }
      );
    });
  }
  async readWhatsAppMessage(messageId: string){

    const url = `${environment.WHAPI_URL}messages/${messageId}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${environment.WHAPI_BEARER_TOKEN}`,
    });

    this.http.put(url, {}, { headers }).subscribe(
      (response) => {
        console.log('Success:', response);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  sendWHAPIImage(url: string,data:any): Observable<any> {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${environment.WHAPI_BEARER_TOKEN}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.WHAPI_URL+url, data, { headers: headers }).pipe(catchError(this.formatErrors));

  }


}
