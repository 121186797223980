<div class="container">
    <div class="row">
        <div class="col-md-12 text-center pb-3">
            <h3>Choose Store</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <!-- <div class="col-md-12">
            <div class="card cardStyle">
                <div class="card-body">
                    <h5 class="card-title">Choose a Store</h5>
                    <div class="card-text">
                        <table width="100%">
                            <tr *ngFor="let data of stores;let i=index">
                                <a style="cursor: pointer;" class="list-group-item list-group-item-action"
                                    (click)="openSelectedStore(data.id)">
                                    <td>{{ data.name }}</td>
                                </a>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <pre>{{stores|json}}</pre> -->
        <ng-container *ngFor="let data of stores;let i=index">
            <div class="col-md-3 text-center">
                <div class="image-container">
                    <img class="img-fluid store-thumb-img" src="{{ FILE_STREAM_URL + data.banner }}" />
                </div>
                <h6 class="py-1" style="height: 2.6em;">{{ data.name }}</h6>
                <p class="address">{{shortstoreName(data.address)}}</p>
                <button type="button" class="btn btn-primary choose_btn" (click)="openSelectedStore(data.id, data.latitude, data.longitude)"> Choose Store</button>
            </div>
        </ng-container>
    </div>
</div>