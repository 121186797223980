
<div class="col-md-8 offset-md-2">
    <span class="anchor" id="formChangePassword"></span>
<div class="card card-outline-secondary">
    <div class="card-header" >
        <h5 class="mb-0">Change Password</h5>
    </div>
    <div class="card-body">
      <form [formGroup]="registerForm"  (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="inputPasswordOld"><span class="required"></span>Current Password</label>
                <input type="password" formControlName="password1" [(ngModel)] ="requestObject.changePassword.oldPassword" class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.password1.errors }"  placeholder="Enter your current password" id="inputPasswordOld" required=""
                [ngClass]="{ 'is-invalid': submitted && f.password1.errors }">
                <div *ngIf="submitted && f.password1.errors" class="invalid-feedback">
                    <div *ngIf="f.password1.errors.required">
                        Current Password is required.
                    </div>
                    <!-- <div *ngIf="f.password1.errors.minlength">Password must be at least 6 characters.</div> -->
                  </div>
                <br>
            </div>
            <div class="form-group">
                <label for="inputPasswordNew"><span class="required"></span>New Password</label>
                <input type="password" formControlName="password2" [(ngModel)]="requestObject.changePassword.newPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password2.errors }"  placeholder="Enter your new password" id="inputPasswordOld" required=""
                [ngClass]="{ 'is-invalid': submitted && f.password2.errors }">
                <div *ngIf="submitted && f.password2.errors" class="invalid-feedback">
                    <div *ngIf="f.password2.errors.required">
                       New Password is required.
                    </div>
                    <!-- <div *ngIf="f.password2.errors.minlength">Password must be at least 6 characters.</div> -->
                    <div *ngIf="f.password2.errors.required">Password is required.</div>
                    <div *ngIf="f.password2.errors.minlength">Password must be at least 8 characters.</div>
                    <div *ngIf="f.password2.errors.maxlength">Password can not be more than 16 characters.</div>
                    <div *ngIf="f.password2.errors.pattern">Password must contain at least one number, one special character & one uppercase letter.</div>
                  </div>
                <br>
            </div>
            <div class="form-group">
                <label for="inputPasswordNewVerify"><span class="required"></span>Confirm New Password</label>              
                <input type="password" formControlName="password3" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password3.errors }"  placeholder="Enter your confirm new password" id="inputPasswordOld" required=""
                [ngClass]="{ 'is-invalid': submitted && f.password3.errors }">
                <div *ngIf="submitted && f.password3.errors" class="invalid-feedback">
                    <div *ngIf="f.password3.errors.required">
                        Confirm new Password is required.
                    </div>
                    <!-- <div *ngIf="f.password3.errors.minlength">Password must be at least 6 characters</div> -->
                    <div *ngIf="f.password3.errors.mustMatch">Passwords must match.</div>
                  </div>
                <br>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-success btn-lg float-right">Submit</button>
            </div>
        </form>
    </div>
</div>
</div>
<!-- 
<div class="login-container">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
              <label>Old Password</label>
              <input type="password" name="old" placeholder="Enter your old password" required class="form-control">
            </div>
          </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>New Password</label>
          <input type="password" name="new" placeholder="Enter your old password" required class="form-control">
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Confirm New Password</label>
          <input type="password" name="pass" placeholder="Enter your confirm new password" required class="form-control">          
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <button  class="btn btn-success pull-right">Submit</button>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div> 
-->