<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="card cardStyle">
                <div class="card-body">
                    <h5 class="card-title">Choose a business</h5>
                    <div class="card-text">
                        <table width="100%">
                            <tr *ngFor="let data of businesses;let i=index">
                                <a class="list-group-item list-group-item-action" style="cursor: pointer;"
                                    (click)="openSelectedBuisness(data.id)">
                                    <td width="55%"><img src="{{fileUrl + data.logo}}" height="60px" /></td>
                                    <td>{{ data.name }}</td>
                                </a>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>