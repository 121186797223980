import { Component, OnInit } from '@angular/core';
import { dasa } from 'src/classess/dasaClasses';
import { test } from 'src/classess/DasaAppUtil';
import { ActivatedRoute, Router } from '@angular/router';
import { commonService } from '../services/common.service';

@Component({
  selector: 'app-choosebusiness',
  templateUrl: './choosebusiness.component.html',
  styleUrls: ['./choosebusiness.component.scss']
})
export class ChoosebusinessComponent implements OnInit {
  businesses: any;
  fileUrl: any;
  requestObject: dasa.RequestObject = new dasa.RequestObject();

  constructor(private commonService_servive: commonService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    test.DasaAppUtil.loadFromSession();
    this.businesses = dasa.Utility.businesses;
    this.fileUrl = dasa.Constant.FILE_STREAM_URL;
    this.businesses = this.businesses.filter((i: any) => i.id != -1);
  }

  openSelectedBuisness(buisnessid: any) {
    if (buisnessid * 1 > 0) {
      dasa.Utility.selectedBusinessId = buisnessid;
      dasa.Utility.selectedCountry = test.DasaAppUtil.getCountryById(test.DasaAppUtil.getBuisnessById(buisnessid).countryId);
      this.requestObject.command = dasa.Constant.ACTION_TYPE_GET_BUSINESS_SETTING;
      this.requestObject.businessId = buisnessid;
      // console.log('input', this.requestObject)
      dasa.Utility.showBusy();
      this.commonService_servive.doAPICall(this.requestObject, dasa.Constant.API_URL).subscribe(
        (res: any) => {
          if (res.success) {
            // console.log('output businessSettings', res)
            var businessSettings = res.businessSettings;
            var stores = businessSettings.stores;
            var users = businessSettings.users;

            dasa.Utility.brands = businessSettings.brands;
            dasa.Utility.categories = businessSettings.categories;
            dasa.Utility.businessItems = businessSettings.businessItems;

            // get business settings and then set thess 3 fields
            dasa.Utility.users = users;
            dasa.Utility.stores = stores;
            dasa.Utility.selectedBusinessId = buisnessid;

            test.DasaAppUtil.putInSession();
            dasa.Utility.hideBusy();
            this.router.navigate(['/admin/dashboard']);
          }
        },
        error => {
          // console.log('error test')
        });
    }
  }
}
