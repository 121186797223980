import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-box-test',
  templateUrl: './map-box-test.component.html',
  styleUrls: ['./map-box-test.component.scss']
})
export class MapBoxTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
