import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { dasa } from '../../classess/dasaClasses';
import { commonService } from 'src/app/services/common.service';
import { elementAt } from 'rxjs';

@Component({
  selector: 'app-whitelevel',
  templateUrl: './whitelevel.component.html',
  styleUrls: ['./whitelevel.component.scss']
})
export class WhitelevelComponent implements OnInit {

  businessId:any;
  requestObject: dasa.RequestObject = new dasa.RequestObject();

  constructor(private activeRoute:ActivatedRoute,private router:Router,private commonService_servive: commonService,) { }

  ngOnInit(): void {
    //const id = Number(this.activeRoute.snapshot.paramMap.get('businessId'));
    this.activeRoute.queryParams.subscribe(params =>{
      this.businessId = params['businessId'];
    })
    this.getBuisnessByID();
  }

  async getBuisnessByID()
  {
    this.requestObject.businessId = parseInt(this.businessId);
    this.requestObject.command = dasa.Constant.ACTION_TYPE_GET_BUSINESS_BY_ID;
    // console.log('Input response data', this.requestObject)    
    //dasa.Utility.showBusy();
    let res = await this.commonService_servive.doAPICall_await(this.requestObject, dasa.Constant.API_URL);
    let res1 :dasa.ResponseObject = res as  dasa.ResponseObject;
    dasa.Utility.business = res1.business ;
    //dasa.Utility.hideBusy();
    // console.log('response item ', res1)
    if(res1.success){
      this.router.navigate(['/']);
    }
    else{
        // alert("Invalid URL");
    }
  }
}
