import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { dasa } from 'src/classess/dasaClasses';



@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
 registerForm: any;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private ele:ElementRef) { }

  ngOnInit(): void {
    //this.ele.nativeElement.querySelector('#spinner').style.visible ='hidden';
    this.ele.nativeElement.querySelector('#spinner').style.display = '';
setTimeout(() => {
   this.ele.nativeElement.querySelector('#spinner').style.display = 'none'
  
}, 1000);

this.registerForm = this.formBuilder.group(
  {
    Name: ['', Validators.required],
    
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
  
  },

);

  }  

  get f() {
   return this.registerForm.controls;
  }

  onSubmit() {
    //dasa.Utility.showBusy();
    this.submitted = true;
    if (this.registerForm.invalid) {
      //dasa.Utility.hideBusy();
      return; 
    }
    alert(
     // 'SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4)
    );
      dasa.Utility.hideBusy();
  }


  // https://stackblitz.com/edit/angular-ivy-mdelzz?file=src%2Fapp%2Fapp.component.ts
}
