
import { Component, LOCALE_ID, Inject, ViewChild, ElementRef  } from '@angular/core';
import { dasa } from 'src/classess/dasaClasses';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dasaapp';
  languageList = [    { code: 'en', label: 'English' },   { code: 'ge', label: 'Deutsch' },    { code: 'fr', label: 'Français' }  ];
  @ViewChild("myBusyDiv") myBusyDiv: ElementRef =  {} as ElementRef;
  
   constructor(@Inject(LOCALE_ID) protected localeId: string) {
      
    }
    ngOnInit(): void {
      if (this.myBusyDiv.nativeElement != undefined) {
        this.myBusyDiv.nativeElement.style.display = 'none';
      }
    }
    ngAfterViewInit():void{
      dasa.Utility.busyDivObject = this.myBusyDiv.nativeElement ;
      dasa.Utility.hideBusy();
      this.myBusyDiv.nativeElement.style.display ='none' ;
    }
}
