 
<!-- <ng-container *ngFor="let language of languageList"> 
  &nbsp;<button class="btn btn-success" (click)="translate.use(language.code)" >
      {{language.label}}
    </button>&nbsp;
</ng-container> -->
<div class="container-fluid h-custom">
  <div class="row d-flex justify-content-center align-items-center h-100">
    <div class="col-md-9 col-lg-6 col-xl-5">
      <img src="{{imageUrl}}" class="img-fluid" alt="Logo" />
    </div>
    <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
          <p class="lead fw-normal mb-0 me-3" i18n="@@login.component.signIn">
            {{ 'LOGIN.signIn' | translate}}
          </p>
        </div>
        <div class="divider d-flex align-items-center my-2">
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1" class="form-label" i18n="@@login.component.email">
            {{ 'LOGIN.email' | translate}}
          </label>
          <input type="email" formControlName="loginemail" [(ngModel)]="requestObject.user.email"
            placeholder="Enter a valid email address" class="form-control mb-0" id="exampleInputEmail1"
            aria-describedby="emailHelp" [ngClass]="{ 'is-invalid': submitted && f.loginemail.errors }">
          <div *ngIf="submitted && f.loginemail.errors" class="invalid-feedback">
            <div *ngIf="f.loginemail.errors.required" i18n="@@login.component.email.required">
              {{ 'LOGIN.email.required' | translate}}
            </div>
            <div *ngIf="f.loginemail.errors.email" i18n="@@login.component.email.error">
              {{ 'LOGIN.email.error' | translate}}
            </div>
          </div>
        </div>
        <div class="form-group mt-4">
          <label for="exampleInputPassword1" class="form-label" i18n="@@login.component.password" >
            {{ 'LOGIN.password' | translate}}
          </label>
          <div class="input-group">
            <input [type]="password" placeholder="Enter password" class="form-control mb-0 pswInput"
              [(ngModel)]="requestObject.user.password" formControlName="loginpassword" id="exampleInputPassword1"
              [ngClass]="{ 'is-invalid': submitted && f.loginpassword.errors }" />
              <div class="input-group-prepend iconDiv" (click)="showHidePass()">
                <i class="fa fa-eye" aria-hidden="true" *ngIf="!showIcon"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="showIcon"></i>
              </div>
            <div *ngIf="submitted && f.loginpassword.errors" class="invalid-feedback">
              <div *ngIf="f.loginpassword.errors.required" i18n="@@login.component.password.required.error" >
                {{ 'LOGIN.password.required' | translate}}
              </div>
              <div *ngIf="f.loginpassword.errors.minlength" i18n="@@login.component.password.minlength.error" >
                {{ 'LOGIN.password.minlength' | translate}}
              </div>
            </div>
          </div>
        </div>
        <div class="login-btn-section form-group mt-4">
          <div class="forgot-wrapper">
            <a routerLink="/forget-password" href="#!" class="text-right" i18n="@@login.component.forgotPassword">
              {{ 'Forgot Password' | translate}}
            </a>
          </div>
          <button type="submit" class="btn btn-primary login-btn" i18n="@@login.component.login">
            {{ 'login' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>