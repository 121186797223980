import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ChoosebusinessComponent } from './choosebusiness/choosebusiness.component';
import { WhitelevelComponent } from './whitelevel/whitelevel.component';
import { ChoosestoreComponent } from './choosestore/choosestore.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';


const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent },
  { path: 'x', component: WhitelevelComponent },
  { path:'chooseBusiness', component:ChoosebusinessComponent},
  { path:'chooseStores', component:ChoosestoreComponent},
  { path: 'forget-password', component: ForgetPasswordComponent },
  {path:'user', canActivate:[],data:{preload:true, role:'u'}, loadChildren:()=> import('./user/user.module').then(m=>m.UserModule)},
  {path:'admin', canActivate:[],data:{preload:true, role:'a'}, loadChildren:()=> import('./admin/admin.module').then(m=>m.AdminModule)},
 
  
  //{ path: 'dashboard', component: DashboardComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
