import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChoosebusinessComponent } from './choosebusiness/choosebusiness.component';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WhitelevelComponent } from './whitelevel/whitelevel.component';
import { ChoosestoreComponent } from './choosestore/choosestore.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ShareModule } from './share/share.module';
import {NgxPrintModule} from 'ngx-print';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChoosebusinessComponent,
    WhitelevelComponent,
    ChoosestoreComponent,
    ForgetPasswordComponent
  ],
  // QuillModule.forRoot(),
  imports: [QuillModule.forRoot(),ToastrModule.forRoot(),
    BrowserModule, FormsModule, ReactiveFormsModule,
    AppRoutingModule, HttpClientModule, OwlDateTimeModule, BrowserAnimationsModule,
    CommonModule, ShareModule, NgxPrintModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }), NgbModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'en' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
//  OwlNativeDateTimeModule, was inside import
