<div class="container-fluid h-custom forgotPassTopMargin">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <img src="{{imageUrl}}" class="img-fluid" alt="Logo" />
      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <h5 class="mb-0">FORGOT PASSWORD</h5>
            <hr />
          </div>
        </div>
        <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
          <!-- 
            <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="password" class="form-label">Password</label>
                <input type="password" placeholder="Enter Password" class="form-control"
                    [(ngModel)]="customer.password" formControlName="password" id="password"
                    [ngClass]="{ 'is-invalid': (submitted || f.password.dirty || f.password.touched) && f.password.errors }">
                <div *ngIf="(submitted || f.password.dirty || f.password.touched) && f.password.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">
                        Password is required
                    </div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
              </div>
            </div>
          </div> 
        -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input type="email" placeholder="Enter Email" class="form-control mb-0" formControlName="email" id="email"
                  [ngClass]="{ 'is-invalid': (submitted || f.email.dirty || f.email.touched) && f.email.errors }">
                <div *ngIf="(submitted || f.email.dirty || f.email.touched) && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">
                    Email is required
                  </div>
                  <!-- <div *ngIf="f.email.errors.mustMatch">Password must match</div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 25px;">
            <div class="col-md-6">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
            <div class="col-md-6 align-self-center text-end">
              Return to <a routerLink="/login" class="text-right">
                Login
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>