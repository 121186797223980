import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-support',
  templateUrl: './app-support.component.html',
  styleUrls: ['./app-support.component.scss']
})
export class AppSupportComponent implements OnInit {
  messages: { text: string; sender: string }[] = [];
  messageInput: string = '';

  constructor() { }

  sendMessage() {
    if (this.messageInput.trim()) {
      this.messages.push({ text: this.messageInput, sender: 'user' });
      this.messageInput = '';
      // Simulate bot/admin response
      setTimeout(() => {
        this.messages.push({ text: 'Thank you for reaching out! We will get back to you soon.', sender: 'support' });
      }, 1000);
    }
  }

  openWhatsApp() {
    window.open('https://api.whatsapp.com/send/?phone=447565176435&text=Hello&type=phone_number&app_absent=0', '_blank'); // Replace with your WhatsApp number
  }

  ngOnInit(): void {
  }

}
