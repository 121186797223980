import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { commonService } from '../services/common.service';
import { dasa } from '../../classess/dasaClasses';
import Swal from 'sweetalert2';
import { test } from 'src/classess/DasaAppUtil';
import { TranslateService } from '@ngx-translate/core';
import { MapBoxTestComponent } from '../share/map-box-test/map-box-test.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  loading = false;
  submitted = false;
  languageList = [{ code: 'en', label: 'English' }, { code: 'de', label: 'Deutsch' }, { code: 'fr', label: 'Français' }];
  translate: any;

  password: String = 'password';
  showIcon = false;

  //user:dasa.User = new dasa.User();
  // user_data={
  //   command:'',
  //   user:{
  //     email:'',
  //     password:''
  //   }
  // }
  // user:any;

  usertype: any;
  requestObject: dasa.RequestObject = new dasa.RequestObject();
  imageUrl: any = './assets/img/dasa_multi_black.png';

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    translate: TranslateService,
    private router: Router, private commonService_servive: commonService) {
    this.translate = translate;
    translate.addLangs(['en', 'de', 'fr']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit(): void {
    if (dasa.Utility.business != undefined && dasa.Utility.business.id > 0) {
      // console.log('Setting image url as :: ' + dasa.Constant.FILE_STREAM_URL + dasa.Utility.business.logo);
      this.imageUrl = dasa.Constant.FILE_STREAM_URL + dasa.Utility.business.logo;
    }

    //this.usertype ='u'
    this.loginForm = this.formBuilder.group({
      loginemail: ['', [Validators.required, Validators.email]],
      loginpassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  showHidePass() {
    if (this.password === 'password') {
      this.password = 'text';
      this.showIcon = true;
    } else {
      this.password = 'password';
      this.showIcon = false;
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.requestObject.command = dasa.Constant.ACTION_TYPE_LOGIN_OK;
    //this.requestObject.user.password = this.user;
    // {
    //   "command":"loginOk",
    //   "user":{
    //     "email": "superadmin@dasa.com",        
    //     "password":"123456"
    //   }
    // }

    // this.user.command ="loginOk"
    // this.user_data.user.email='superadmin@dasa.com'
    // this.user_data.user.password ='123456'
    //this.user_data.push(this.user)

    //console.log('gautam', result)

    // if(result.success)
    // {
    //   sessionStorage.setItem('globalvariable', JSON.stringify(result));
    //   this.router.navigate(['/admin/dashboard'])
    // }

    dasa.Utility.showBusy();
    this.commonService_servive.doAPICall(this.requestObject, dasa.Constant.API_URL).subscribe(
      (res: any) => {
        if (res.success && res.user.id > 0) {
          dasa.Utility.users = res.users;
          dasa.Utility.businesses = res.businesses;
          dasa.Utility.loggedInUser = res.user;
          dasa.Utility.countries = res.countries;
          if (res.user.userGroupId * 1 == dasa.Constant.USER_TYPE_SUPER_ADMIN) {
            test.DasaAppUtil.putInSession();
            // console.log('login successfule redirect to dashboard', res);
            //let obj_dasa: dasa.Utility = new dasa.Utility() ;
            //   sessionStorage.setItem('globalvariable', JSON.stringify(res));

            dasa.Utility.hideBusy();
            this.router.navigate(['/admin/dashboard'])
          } else if (res.user.userGroupId * 1 == dasa.Constant.USER_TYPE_BUSINESS_ADMIN || res.user.userGroupId * 1 == dasa.Constant.USER_TYPE_BUSINESS_ADMIN_cum_STORE_ADMIN) {
            if (res.businesses.length > 1) {
              // console.log('login successfule redirect to dashboard', res);
              dasa.Utility.businesses = res.businesses;
              test.DasaAppUtil.putInSession();
              dasa.Utility.hideBusy();
              this.router.navigate(['/chooseBusiness']);
              /// show screen to choose between businesses wait
            } else {
              // 
              // console.log("check login res==>", res);
              var businessSettings = res.businessSettings;
              var stores = businessSettings.stores;
              var users = businessSettings.users;
              // console.log('res.businesses :: ' + res.businesses);
              var businessId = res.businesses[0].id;
              // console.log('businessId :: ' + businessId);
              dasa.Utility.selectedCountry = test.DasaAppUtil.getCountryById(res.businesses[0].countryId);
              dasa.Utility.users = users;
              dasa.Utility.stores = stores;
              dasa.Utility.selectedBusinessId = businessId;

              dasa.Utility.brands = businessSettings.brands;
              dasa.Utility.categories = businessSettings.categories;
              dasa.Utility.businessItems = businessSettings.businessItems;

              test.DasaAppUtil.putInSession();
              // console.log('login successfule redirect to dashboard', res);
              //let obj_dasa: dasa.Utility = new dasa.Utility() ;
              //  sessionStorage.setItem('globalvariable', JSON.stringify(res));
              dasa.Utility.hideBusy();
              this.router.navigate(['/admin/dashboard']);
            }
          }
          else if (res.user.userGroupId * 1 == dasa.Constant.USER_TYPE_STORE_ADMIN) {
            let myStoreSettings = res.myStoreSettings;
            dasa.Utility.users = myStoreSettings.users;
            dasa.Utility.stores = myStoreSettings.stores;
            // console.log("check business Id", res.businesses[0])
            dasa.Utility.selectedCountry = test.DasaAppUtil.getCountryById(res.businesses[0].countryId);
            if (myStoreSettings.stores.length > 1) {
              // console.log('login successfule store admin redirect to dashboard', res);
              dasa.Utility.stores = myStoreSettings.stores;
              test.DasaAppUtil.putInSession();
              dasa.Utility.hideBusy();
              this.router.navigate(['/chooseStores']);
              /// show screen to choose between businesses wait
            } else {
              //console.log(myStoreSettings.stores[0].latitude);
              // set lat long
              if(myStoreSettings.stores[0]?.latitude !== undefined && myStoreSettings.stores[0]?.latitude !== null){
                dasa.Utility.latitude = Number(myStoreSettings.stores[0].latitude);
              dasa.Utility.longitude = Number(myStoreSettings.stores[0].longitude);
              test.DasaAppUtil.putInSession();

              // shoow screen to choose between stores
              dasa.Utility.selectedBusinessId = res.businesses[0].id;
              dasa.Utility.selectedStoreId = myStoreSettings.stores[0].id;
              dasa.Utility.brands = myStoreSettings.brands;
              dasa.Utility.categories = myStoreSettings.categories;
              dasa.Utility.storeItems = myStoreSettings.storeItems;
              dasa.Utility.storeSettings = myStoreSettings.storeSettings;
              test.DasaAppUtil.putInSession();
              // dasa.Utility.hideBusy();

              // register for notification 
              this.requestObject.command = dasa.Constant.ACTION_TYPE_REGISTER_USER_NOTIFICATION;
              this.requestObject.storeId = dasa.Utility.selectedStoreId;
              this.requestObject.storeAdminId = dasa.Utility.loggedInUser.id;
              this.commonService_servive.doAPICall(this.requestObject, dasa.Constant.API_URL).subscribe((res: any) => {
                dasa.Utility.socketId = res.socketId;
                // sessionStorage.setItem("sessionSocketId", JSON.stringify(dasa.Utility.socketId));
                test.DasaAppUtil.putInSession();
                // go to dashboard page
                dasa.Utility.hideBusy();
                this.router.navigate(['/admin/new-reports']);
                // this.router.navigate(['/admin/dashboard']);
              });
              }
              else{
                dasa.Utility.hideBusy();
          Swal.fire({
            text: 'You are not authorized to login. Please contact the Admin.',
            icon: 'error',
            timer: 1000,
            position: 'center',
            heightAuto:false
          });
              }
              
              // register for notification
            }
          }
        }
        else {
          dasa.Utility.hideBusy();
          Swal.fire({
            text: 'You are not authorized to login.',
            icon: 'error',
            timer: 1000,
            position: 'center',
            heightAuto:false
          });
        }
      },
      error => {
        dasa.Utility.hideBusy();
        // console.log('error test', error)
      }
    )
    // if(this.usertype =='u')
    // {  
    // }
  }
}
