import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareComponent } from './share.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RegistrationComponent } from './registration/registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MapBoxTestComponent } from './map-box-test/map-box-test.component';



@NgModule({
  declarations: [
    ShareComponent,
    ChangePasswordComponent,
    RegistrationComponent,
    MapBoxTestComponent
  ],
  imports: [
    
    CommonModule,    
    FormsModule,ReactiveFormsModule
  ]
})
export class ShareModule { }
