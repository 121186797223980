import { Component, OnInit } from '@angular/core';
import { dasa } from 'src/classess/dasaClasses';
import { test } from 'src/classess/DasaAppUtil';
import { ActivatedRoute, Router } from '@angular/router';
import { commonService } from '../services/common.service';

@Component({
  selector: 'app-choosestore',
  templateUrl: './choosestore.component.html',
  styleUrls: ['./choosestore.component.scss']
})
export class ChoosestoreComponent implements OnInit {

  stores: dasa.Store[] = [];
  requestObject: dasa.RequestObject = new dasa.RequestObject();
  loggedInuser: any;
  FILE_STREAM_URL: any;

  constructor(
    private commonService_servive: commonService, 
    private route: ActivatedRoute, 
    private router: Router
  ) { }

  ngOnInit(): void {
    this.FILE_STREAM_URL = dasa.Constant.FILE_STREAM_URL;
    test.DasaAppUtil.loadFromSession();
    this.stores = dasa.Utility.stores;
    this.loggedInuser = dasa.Utility.loggedInUser;
  }

  /**
   * TODO: will implement later 
   * @param storeid 
   */
  openSelectedStore(storeid: any, lat: any, long: any) {
    dasa.Utility.showBusy();
    if (storeid * 1 > 0) {
      dasa.Utility.selectedStoreId = storeid;
      dasa.Utility.selectedBusinessId = dasa.Utility.businesses[0].id;
      // hit server to get myStoreSetting
      this.requestObject.command = dasa.Constant.ACTION_TYPE_GET_MY_STORE_SETTING;
      this.requestObject.storeId = dasa.Utility.selectedStoreId;
      this.requestObject.businessId = dasa.Utility.selectedBusinessId;
      // console.log("getmystoresetting ::s" + JSON.stringify(this.requestObject));
      this.commonService_servive.doAPICall(this.requestObject, dasa.Constant.API_URL).subscribe(
        (res: any) => {
          dasa.Utility.users = res.myStoreSettings.users;
          dasa.Utility.brands = res.myStoreSettings.brands;
          dasa.Utility.categories = res.myStoreSettings.categories;
          dasa.Utility.storeItems = res.myStoreSettings.storeItems;
          dasa.Utility.storeSettings = res.myStoreSettings.storeSettings;
          // console.log(res);
          test.DasaAppUtil.putInSession();
          // register for notification 
          this.requestObject.command = dasa.Constant.ACTION_TYPE_REGISTER_USER_NOTIFICATION;
          this.requestObject.storeId = dasa.Utility.selectedStoreId;
          this.requestObject.storeAdminId = dasa.Utility.loggedInUser.id;
          this.commonService_servive.doAPICall(this.requestObject, dasa.Constant.API_URL).subscribe((res: any) => {
            // sessionStorage.setItem("sessionSocketId", JSON.stringify(dasa.Utility.socketId));
            dasa.Utility.socketId = res.socketId;
            test.DasaAppUtil.putInSession();
            });
          // register for notification
          // this.router.navigate(['/admin/dashboard']);
          dasa.Utility.latitude = Number(lat);
          dasa.Utility.longitude = Number(long);
          test.DasaAppUtil.putInSession();
          // dasa.Utility.hideBusy();
          this.router.navigate(['/admin/new-reports']);
        });
    }
  }

  shortstoreName(storeName: any) {
    if (storeName.length >= 75) {
      return `${storeName.substr(0, 75)}...`;
    }
    return storeName;
  }
}
