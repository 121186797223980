<div class="col-md-8 offset-md-2">
    <span class="anchor" id="formRegistartion"></span>
   <div class="card card-outline-secondary">
    <div class="card-header" >
        <h5 class="mb-0">Add User</h5>
    </div>
    <div class="card-body">
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="inputPasswordOld">Name</label>
                <input type="text" class="form-control" 
                  formControlName="Name" placeholder="Enter your name"  required="" 
                  [ngClass]="{ 'is-invalid': submitted && f.Name.errors }">
                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                    <div *ngIf="f.Name.errors.required">
                       Name is required
                    </div>
                  </div>
                <br>
            </div>
            <div class="form-group">
                <div class="col-md-10 pb-1" >
                    <label for="exampleAccount">Gender</label>
                    <div class="form-group small">                  
                        <div class="form-check form-check-inline" style="margin-right: 50px;">
                            <label class="form-check-label">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"> Male
                            </label>
                        </div>
                        <div class="form-check form-check-inline" style="margin-right: 50px;">
                            <label class="form-check-label">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"> Female
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"> Transgender
                            </label>
                        </div>
                    </div>
                </div>    
            </div>
            <div class="form-group">
                <label for="inputPasswordNewVerify">Date of Birth</label>
                <input type="text" class="form-control" placeholder="Enter your confirm new password" id="inputPasswordNewVerify" required="">
                <br> 
            </div>
            <div class="form-group">
                <label for="inputPasswordOld">Phone</label>
                <input type="text" formControlName="phone"  class="form-control"  placeholder="Enter your phone" id="inputPasswordOld" required=""
                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.phone.errors.required">
                        Phone is required
                    </div>
                  </div>
                <br>
            </div>
            <div class="form-group">
                <label for="inputPasswordOld">Email</label>
                <input type="email"  formControlName="email" class="form-control"  placeholder="Enter your email"  required=""
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">
                       Email is required
                    </div>
                  </div>
                  <br>
            </div>
            <div class="form-group">
                <label for="inputPasswordOld">Status</label>
                <input type="text" class="form-control"  placeholder="Enter your current password" id="inputPasswordOld" required=""><br>
            </div>
            <div class="form-group">
                <label for="inputPasswordOld">Password</label>
                <input type="password" class="form-control"  placeholder="Enter your password" id="inputPasswordOld" required=""><br>
            </div>
            <div class="form-group">
                <label for="inputPasswordOld">Confirm Password</label>
                <input type="password" class="form-control"  placeholder="Enter your confirm password" id="inputPasswordOld" required=""><br>
            </div>
            <div class="form-group">
                <label for="inputPasswordOld">Picture</label>
                    <input type="file" class="form-control" />          
                    <button class="btn btn-success">Upload</button>
                <br>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-success btn-sm">Submit</button>&nbsp;
                <button type="button" class="btn btn-danger btn-sm">Cancel</button>
            </div>
        </form>
    </div>
</div>
</div>
<div id="spinner">
    <p id="spinnertext"><label>Processing...please wait.</label></p>
</div>