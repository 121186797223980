import { Component, OnInit,ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../confirm-password.validator';
import {dasa} from '../../../classess/dasaClasses';
import {test} from '../../../classess/DasaAppUtil';
import Swal from 'sweetalert2';
import { commonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  registerForm: any;
  submitted = false;

  constructor(
    private router:Router, 
    private activeRoute:ActivatedRoute,  
    private commonService_servive:commonService,
    private formBuilder: FormBuilder,  
    private ele:ElementRef) { }

  requestObject:dasa.RequestObject =new  dasa.RequestObject();

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
        password1: ['', [Validators.required,]],
        password2: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/)]],
        password3: ['', [Validators.required]]              
      },{
        validator: MustMatch('password2', 'password3')
      });

    this.requestObject.changePassword.userId = dasa.Utility.loggedInUser.id;
    this.requestObject.changePassword.modifiedBy = dasa.Utility.loggedInUser.id;
  }

  get f() {
    return this.registerForm.controls;
   }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.requestObject.command = dasa.Constant.ACTION_TYPE_CHANGE_PASSWORD_OK; 
    // console.log('input cp', JSON.stringify(this.requestObject));
    //calling change password option
    this.commonService_servive.doAPICall(this.requestObject, dasa.Constant.API_URL).subscribe((res :any)=>{  
        // console.log('output', res)
        if(res.success) {
          Swal.fire({                
            text: 'Password updated successfully.',
            icon: 'success',
            timer: 1000
          });
          test.DasaAppUtil.removeFromSession();
          this.router.navigate(['/']);
        } else {
          Swal.fire({
            text: 'Password couldn\'t updated.',
            icon: 'error',
            timer: 1000
          });
        }
      },
      error =>{
        // console.log('error test')
      })
}
}
