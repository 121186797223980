<div class="title">Welcome to dasa support</div>
<div class="chat-container">
    <div *ngFor="let msg of messages" [ngClass]="{'user-msg': msg.sender === 'user', 'support-msg': msg.sender === 'support'}">
      {{ msg.text }}
    </div>
    <div class="input-container">
      <input [(ngModel)]="messageInput" placeholder="Type your message..." />
      <button (click)="sendMessage()">Send</button>
    </div>
    <button class="whatsapp-btn" (click)="openWhatsApp()">Chat on WhatsApp</button>
  </div>

  <div class="row text-center mt-5">
    <div class="col-sm-12 col-md-4 col-lg-4"> Phone: +447565176435</div>
    <div class="col-sm-12 col-md-4 col-lg-4"> Email: Letstalk@thedasagroup.com</div>
    <div class="col-sm-12 col-md-4 col-lg-4">Address: 25 Luke St, London EC2A 4DS, United Kingdom</div>
  </div>