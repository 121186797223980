import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { commonService } from 'src/app/services/common.service';
import { dasa } from 'src/classess/dasaClasses';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html'
})
export class ForgetPasswordComponent implements OnInit {
  
  forgetPasswordForm: any;
  submitted = false;
  requestObject: dasa.RequestObject = new dasa.RequestObject();
  customer: dasa.Customer = {} as dasa.Customer;
  imageUrl: any = './assets/img/dasa_multi_black.png';
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private commonService_servive: commonService
  ) { }

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
    this.customer = new dasa.Customer();
  }

  get f() { return this.forgetPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.forgetPasswordForm.invalid) {
      return;
    }
    let requestObject: dasa.RequestObject = new dasa.RequestObject();
    requestObject.command = dasa.Constant.ACTION_TYPE_RECOVER_PASSWORD_OK;
    requestObject.appType = 'app'; // app
    requestObject.email = this.forgetPasswordForm.value.email;
    this.commonService_servive.doAPICall(requestObject, dasa.Constant.API_URL).subscribe(
      (res: dasa.ResponseObject) => {
        if (res.success) {
          Swal.fire({
            text: res.message,
            icon: 'success',
            timer: 1000
          });
        } else {
          Swal.fire({
            text: 'Authentication Failed',
            icon: 'error',
            timer: 1000
          });
        }
      });
  }
}
